export const getMinHotelPrice = (roomTypes, currency = 'RUB') => {
    let priceInfo = '';

    currency = currency ? currency : 'RUB';

    if (!currency) return priceInfo;

    for (const roomType in roomTypes) {
        const roomMinPrice = roomTypes[roomType] && roomTypes[roomType]['minPrice'] ? roomTypes[roomType]['minPrice'] : null;
        if (!priceInfo && roomMinPrice
            || (roomMinPrice && roomMinPrice['price'][currency] < priceInfo['price'][currency])) {
            priceInfo = roomMinPrice;
        }
    }

    return priceInfo;
};